import React, { useState } from "react";

import { DoneModal } from "./components/styles/DoneModal";
import { HelloModal } from "./components/styles/HelloModal";
import { OrientationModal } from "./components/styles/OrientationModal";
import Tetris from "./components/Tetris";

const App = () => {
  const [status, setStatus] = useState(0);
  const [score, setScore] = useState(0);
  return (
    <div className="App">
      {status === 1 && (
        <Tetris
          onComplete={(score) => {
            setStatus(2);
            setScore(score);
          }}
        />
      )}
      <OrientationModal />
      {status === 0 && <HelloModal onStart={() => setStatus(1)} />}
      {status === 2 && <DoneModal score={score} onStart={() => setStatus(1)} />}
    </div>
  );
};

export default App;
