import { Header, M } from "./Header";

import React from "react";
import styled from "styled-components";
import { targetScore } from "./config";

const Go = styled.div`
  background: rgb(39, 51, 109);
  color: #ffffff;
  padding: 1rem 4rem;
  border-radius: 3px;
`;

const Footer = styled.div`
  background-color: #a0a2a4;
  color: #ffffff;
  padding: 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  a {
    text-decoration: none;
    color: #ffffff;
    font-size: 0.75rem;
  }
`;

export const HelloModal = ({ onStart }) => {
  return (
    <M>
      <Header />
      <p>
        <span role="img" aria-label="">🚨🚨🚨</span>
        <br />
        <b>Hallo!</b>
      </p>
      <p>
        Vielen Dank, dass Du unserem Lagermeister beim Laden unseres Trucks
        hilfst, damit wir noch schneller zum Aufbau des heutigen Events kommen.
        Bitte stelle sicher, dass mindestens {targetScore} Cases in den Truck
        geladen werden.
      </p>
      <p>Schaffst Du das, wartet eine tolle Überraschung auf Dich!</p>
      <Go onClick={() => onStart()}>
        <span>
          <span role="img" aria-label="">
            🚀
          </span>
          &nbsp; &nbsp;Los geht´s!
        </span>
      </Go>
      <Footer>
        <a href="https://www.lite-tech.de/impressum/">Impressum</a>
      </Footer>
    </M>
  );
};
