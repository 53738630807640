import React from "react";
import { Textfit } from "react-textfit";
import styled from "styled-components";

const Square = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  padding: 20px;
  border: 4px solid #333;
  // min-height: 30px;
  // width: 100%;
  border-radius: 20px;
  color: ${(props) => (props.gameOver ? "red" : "#999")};
  background: #000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  position: relative;
  width: 100%;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const Content = styled(Textfit)`
  position: absolute;
  left: 10%;
  top: 10%;
  bottom: 10%;
  right: 10%;
  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: normal;
    vertical-align: middle;
  }
`;

export const StyledDisplay = ({ children, gameOver }) => {
  return (
    <Square gameOver={gameOver}>
      <Content mode="multi" max={30}>
        {children}
      </Content>
    </Square>
  );
};
