export const TETROMINOS = {
  0: { shape: [[0]], color: "0, 0, 0" },
  I: {
    shape: [[0, "I", 0, 0], [0, "I", 0, 0], [0, "I", 0, 0], [0, "I", 0, 0]],
    color: "249, 215, 28",
  },
  J: {
    shape: [[0, "J", 0], [0, "J", 0], ["J", "J", 0]],
    color: "249, 215, 28",
  },
  L: {
    shape: [[0, "L", 0], [0, "L", 0], [0, "L", "L"]],
    color: "249, 215, 28",
  },
  O: { shape: [["O", "O"], ["O", "O"]], color: "249, 215, 28", active: 1 },
  S: {
    shape: [[0, "S", "S"], ["S", "S", 0], [0, 0, 0]],
    color: "249, 215, 28",
  },
  T: {
    shape: [[0, 0, 0], ["T", "T", "T"], [0, "T", 0]],
    color: "249, 215, 28",
  },
  Z: {
    shape: [["Z", "Z", 0], [0, "Z", "Z"], [0, 0, 0]],
    color: "249, 215, 28",
  },
};

export const randomTetromino = () => {
  const tetrominos = "IJLOSTZ";
  // const tetrominos = "I";
  const randTetromino =
    tetrominos[Math.floor(Math.random() * tetrominos.length)];
  return TETROMINOS[randTetromino];
};
