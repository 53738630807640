import b from "./case";
import styled from "styled-components";

export const StyledCell = styled.div`
  width: auto;
  background: ${(props) =>
    props.type === 0
      ? "rgba(0,0,0,0)"
      : `rgba(0,0,0,0) ${b} no-repeat center center`};
  
  background-size: contain;

  // border-radius: 5px;
  
  // background: rgba(${(props) => props.color}, 0.8);
  // border: ${(props) => (props.type === 0 ? "0px solid" : "4px solid")};

  // border-bottom-color: rgba(170,169,173, 0.6);
  // border-right-color: rgba(170,169,173, 1);
  // border-top-color: rgba(170,169,173, 1);
  // border-left-color: rgba(170,169,173, 0.8);
  
  // border-bottom-color: rgba(${(props) => props.color}, 0.1);
  // border-right-color: rgba(${(props) => props.color}, 1);
  // border-top-color: rgba(${(props) => props.color}, 1);
  // border-left-color: rgba(${(props) => props.color}, 0.3);

`;
