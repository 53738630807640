// BG Image
import bgImage from "../../img/bg.png";
import styled from "styled-components";

export const StyledTetrisWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: url(${bgImage}) #000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
`;

export const StyledTetris = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 40px;
  overflow: hidden;
  margin: 0 auto;
  // max-width: 375px;
  height: calc(100% - 80px);
  aside {
    width: 100%;
    height: 80%;
    max-width: 20vw;
    display: flex;
    padding: 0 0 0 20px;
    flex-direction: column;
  }
`;
