import { DekoBottom, DekoTop, DekoWrapper, Master } from "./styles/Deko";
import React, { useCallback, useEffect, useState } from "react";
import { StyledTetris, StyledTetrisWrapper } from "./styles/StyledTetris";
import { checkCollision, createStage } from "../gameHelpers";

import Display from "./Display";
// Components
import Stage from "./Stage";
import StartButton from "./StartButton";
import { useGameStatus } from "../hooks/useGameStatus";
// Custom Hooks
import { useInterval } from "../hooks/useInterval";
import { usePlayer } from "../hooks/usePlayer";
import { useStage } from "../hooks/useStage";

const baseSpeed = 650;
const fak = 0.7;

const Tetris = ({ onComplete }) => {
  const [dropTime, setDropTime] = useState(null);
  const [gameOver, setGameOver] = useState(false);

  const [player, updatePlayerPos, resetPlayer, playerRotate] = usePlayer();
  const [stage, setStage, rowsCleared] = useStage(player, resetPlayer);
  const [score, setScore, rows, setRows, level, setLevel] = useGameStatus({
    rowsCleared,
    stage,
  });

  const movePlayer = (dir) => {
    if (!checkCollision(player, stage, { x: dir, y: 0 })) {
      updatePlayerPos({ x: dir, y: 0 });
    }
  };

  const keyUp = ({ keyCode }) => {
    if (!gameOver) {
      // Activate the interval again when user releases down arrow.
      if (keyCode === 40) {
        setDropTime(baseSpeed / (level * fak + 1));
      }
    }
  };

  const startGame = useCallback(() => {
    // Reset everything
    setStage(createStage());
    setDropTime(baseSpeed);
    resetPlayer();
    setScore(0);
    setLevel(0);
    setRows(0);
    setGameOver(false);
  }, [resetPlayer, setLevel, setRows, setScore, setStage]);

  useEffect(() => {
    startGame();
  }, [startGame]);

  const drop = () => {
    // Increase level when player has cleared 5 rows
    if (rows > (level + 1) * 5) {
      setLevel((prev) => prev + 1);
      // Also increase speed
      setDropTime(baseSpeed / (level * fak + 1) + 200);
    }

    if (!checkCollision(player, stage, { x: 0, y: 1 })) {
      updatePlayerPos({ x: 0, y: 1, collided: false });
    } else {
      // Game over!
      if (player.pos.y < 1) {
        console.log("GAME OVER!!!");
        setGameOver(true);
        setDropTime(null);
        onComplete(score);
      }
      updatePlayerPos({ x: 0, y: 0, collided: true });
    }
  };

  const dropPlayer = () => {
    // We don't need to run the interval when we use the arrow down to
    // move the tetromino downwards. So deactivate it for now.
    setDropTime(null);
    drop();
  };

  // This one starts the game
  // Custom hook by Dan Abramov
  useInterval(() => {
    drop();
  }, dropTime);

  const move = ({ keyCode }) => {
    if (!gameOver) {
      if (keyCode === 37) {
        movePlayer(-1);
      } else if (keyCode === 39) {
        movePlayer(1);
      } else if (keyCode === 40) {
        dropPlayer();
      } else if (keyCode === 38) {
        playerRotate(stage, 1);
      }
    }
  };

  const onMouseOrTouchMove = (c) => {
    let dir = 0;
    if (c < player.pos.x) {
      dir = -1;
    } else if (c > player.pos.x) {
      dir = +1;
    }
    console.log(player.pos.x, c, dir);

    if (!checkCollision(player, stage, { x: dir, y: 0 })) {
      updatePlayerPos({ x: dir, y: 0 });
    }
  };

  const onClickOrTouch = () => {
    playerRotate(stage, 1);
  };

  return (
    <StyledTetrisWrapper
      role="button"
      tabIndex="0"
      onKeyDown={(e) => move(e)}
      onKeyUp={keyUp}
    >
      <StyledTetris>
        <DekoWrapper>
          <DekoTop />
          <Stage
            stage={stage}
            onMouseOrTouchMove={onMouseOrTouchMove}
            onClickOrTouch={onClickOrTouch}
          />
          <DekoBottom />
        </DekoWrapper>
        <aside>
          <StartButton callback={startGame} />
          <Display gameOver={gameOver} text={score} />
          <Master />
        </aside>
      </StyledTetris>
    </StyledTetrisWrapper>
  );
};

export default Tetris;
