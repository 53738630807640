import React, { useState } from "react";

import Cell from "./Cell";
import { StyledStage } from "./styles/StyledStage";

function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

const Stage = ({ stage, onMouseOrTouchMove, onClickOrTouch }) => {
  const [wasMove, setWasMove] = useState(false);

  return (
    <StyledStage
      width={stage[0].length}
      height={stage.length}
      onTouchMove={(e1) => {
        const touch = e1.targetTouches[0];

        const parentRect = e1.currentTarget.getBoundingClientRect();

        const offset = parentRect.x;
        const touchX = touch.clientX;
        const x = touchX - offset - parentRect.width * 0.1;

        let cleanX = Math.min(Math.max(0, x), parentRect.width);
        let p = cleanX / parentRect.width;
        let c = Math.round(p * stage[0].length);

        setWasMove(true);
        onMouseOrTouchMove(c - 1);
      }}
      onTouchStart={() => {
        setWasMove(false);
      }}
      onTouchEnd={(e) => {
        if (!wasMove) {
          onClickOrTouch();
        }
      }}
      onMouseMove={
        !isTouchDevice
          ? (e) => {
              let rect = e.currentTarget.getBoundingClientRect();
              let x = e.clientX - rect.left;
              let cleanX = Math.min(Math.max(0, x), rect.width);
              let p = cleanX / rect.width;
              let c = Math.round(p * stage[0].length);
              onMouseOrTouchMove(c);
            }
          : () => {}
      }
      onClick={
        !isTouchDevice
          ? () => {
              onClickOrTouch();
            }
          : () => {}
      }
    >
      {stage.map((row) =>
        row.map((cell, x) => <Cell key={x} type={cell[0]} />)
      )}
    </StyledStage>
  );
};

export default Stage;
