import React from "react";
import styled from "styled-components";

export const M = styled.div`
  display: none;
  @media screen and (min-width: 320px) and (orientation: landscape) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #fff;
    line-height: 100vh;
    vertical-align: middle;
    text-align: center;
    font-size: 8vh;
  }
`;

const SVG = styled.svg`
  width: 20vh;
  height: 20vh;
`;

export const OrientationModal = () => {
  return (
    <M>
      <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <path d="M6,64H58a6,6,0,0,0,6-6V6a6,6,0,0,0-6-6H30a6,6,0,0,0-6,6V24H6a6,6,0,0,0-6,6V58A6,6,0,0,0,6,64ZM38.08,4H49.92V5H38.08ZM28,6a2,2,0,0,1,2-2h4.08V7a2,2,0,0,0,2,2H51.92a2,2,0,0,0,2-2V4H58a2,2,0,0,1,2,2V58a2,2,0,0,1-2,2H30a2,2,0,0,1-2-2ZM4,38.08H5V49.92H4ZM4,53.92H7a2,2,0,0,0,2-2V36.08a2,2,0,0,0-2-2H4V30a2,2,0,0,1,2-2H24V58a6,6,0,0,0,.35,2H6a2,2,0,0,1-2-2Z" />
        <path d="M4.5,23a2,2,0,0,0,2-2A10,10,0,0,1,15.61,11l-1.23,1.43a2,2,0,0,0,.22,2.82,2,2,0,0,0,1.3.48,2,2,0,0,0,1.52-.69l4.27-5a2,2,0,0,0-.22-2.82L16.49,3a2,2,0,1,0-2.6,3l1.19,1A14,14,0,0,0,2.5,21,2,2,0,0,0,4.5,23Z" />
      </SVG>
    </M>
  );
};
