import React from "react";
import styled from "styled-components";

const StyledStartButton = styled.button`
  box-sizing: border-box;
  margin: 0 0 20px 0;
  padding: 20px;
  min-height: 30px;
  width: 100%;
  border-radius: 20px;
  border: none;
  color: white;
  background: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
`;

const StartButton = ({ callback }) => (
  <StyledStartButton onClick={callback}>
    <svg
      id="Layer_1"
      enableBackground="new 0 0 32 32"
      height="24"
      viewBox="0 0 32 32"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#fff" d="m30 14c-1.104 0-2 .896-2 2 0 6.617-5.383 12-12 12s-12-5.383-12-12 5.383-12 12-12c2.943 0 5.736 1.085 7.913 3h-2.697c-1.104 0-2 .896-2 2s.896 2 2 2h6.957c1.104 0 2-.896 2-2v-6.956c0-1.104-.896-2-2-2s-2 .896-2 2v1.633c-2.844-2.351-6.417-3.677-10.173-3.677-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16c0-1.104-.896-2-2-2z" />
    </svg>
  </StyledStartButton>
);

export default StartButton;
