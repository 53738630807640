import { Go, Header, M } from "./Header";
import React, { useState } from "react";

import axios from "axios";
import styled from "styled-components";
import { targetScore } from "./config";

const Input = styled.input`
  text-align: center;
  padding: 1rem;
  border-radius: 12px;
  max-width: 240px;
  width: 80%;
  margin: 0 auto 1rem auto;
`;

const Scroll = styled.div`
  max-height: calc(100vh - 128px);
  overflow-y: scroll;
  padding: 0px 48px;
`;

export const DoneModal = ({ score, onStart }) => {
  const [email, setEMail] = useState("");
  const [street, setStreet] = useState("");
  const [postal, setPostal] = useState("");
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [sent, setSent] = useState(false);
  const sendMail = () => {
    axios
      .get(
        `https://game.lite-tech.de/mail.php?email=${email}&name=${name}&addr=${street}\n${postal} ${city}&score=${score}`
      )
      .then(() => {
        setSent(true);
      })
      .catch((e) => {
        debugger;
        alert("Fehler!");
      });
  };

  const winner = score >= targetScore;

  if (winner && sent) {
    return (
      <M>
        <Header />
        <Scroll>
          <p>
            <span role="img" aria-label="">
              🎉🎉🎉
            </span>
            <br />
            <b>Super!</b>
          </p>
          <p>
            Unsere kleine Überraschung für dich ist auf dem Weg und wird in den
            nächsten Tagen bei dir eintreffen. Viel Spaß! Willst du deinen Score
            noch verbessern?
          </p>
          <br />
          <Go onClick={() => onStart()}>
            <span>
              <span role="img" aria-label="">
                🚀
              </span>
              &nbsp;&nbsp;Los geht´s!
            </span>
          </Go>
        </Scroll>
      </M>
    );
  } else if (winner && !sent) {
    return (
      <M>
        <Header />
        <Scroll>
          <p>
            <span role="img" aria-label="">
              🎉🎉🎉
            </span>
            <br />
            <b>Super!</b>
          </p>
          <p>
            Du hast es geschafft und alle benötigten Cases sind sicher im Truck
            verstaut. Unser Team kann nun losfahren und Du hast Dir eine tolle
            Belohnung verdient. Gib hier Deine Kontaktdaten ein und freue Dich
            auf eine kleine Überraschung, die wir die zusenden werden.
          </p>
          <p>E-Mail</p>
          <Input
            name="email"
            type="email"
            autocomplete="email"
            onChange={(e) => setEMail(e.target.value)}
            value={email}
          />
          <p>Vorname Nachname</p>
          <Input
            name="name"
            type="text"
            autocomplete="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <p>Straße und Hausnummer</p>
          <Input
            name="street"
            type="text"
            autocomplete="address-line1"
            onChange={(e) => setStreet(e.target.value)}
            value={street}
          />
           <p>Postleitzahl</p>
          <Input
            name="postal"
            type="text"
            autocomplete="postal-code"
            onChange={(e) => setPostal(e.target.value)}
            value={postal}
          />
          <p>Stadt</p>
          <Input
            name="city"
            type="text"
            autocomplete="address-level2"
            onChange={(e) => setCity(e.target.value)}
            value={city}
          />
          <Go onClick={() => sendMail()}>
            <span>Absenden</span>
          </Go>
        </Scroll>
      </M>
    );
  } else {
    return (
      <M>
        <Header />
        <Scroll>
          <p>
            <span role="img" aria-label="">
              🫣🫣🫣
            </span>
            <br />
            <b>Schade!</b>
          </p>
          <p>
            Mist, leider fehlen noch wichtige Teile im Truck, ohne die wir nicht
            zu unserem Auftrag losfahren können. Wir brauchen mindestens{" "}
            {targetScore} Cases an Bord! Bitte hilf unserem Lagermeister bei
            einem neuen Versuch.
          </p>
          <p>Schaffst Du das, wartet eine tolle Überraschung auf Dich!</p>
          <br />
          <Go onClick={() => onStart()}>
            <span>
              <span role="img" aria-label="">
                🚀
              </span>
              &nbsp;&nbsp;Los geht´s!
            </span>
          </Go>
        </Scroll>
      </M>
    );
  }
};
