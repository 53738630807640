import React from "react";
import styled from "styled-components";

const H = styled.div`
  background-color: rgb(39, 51, 109);
  color: #ffffff;
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`;

export const Header = () => {
  return (
    <H>
      <img
        alt="logo"
        src={
          "https://www.lite-tech.de/wp-content/uploads/2016/12/footer-logo.png"
        }
        width="50%"
      />
    </H>
  );
};

export const M = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: #fff;
  vertical-align: middle;
  text-align: center;
  font-size: 1.25rem;
  padding: 88px 0px;
`;

export const Go = styled.div`
  background: rgb(39, 51, 109);
  color: #ffffff;
  padding: 1rem 4rem;
  border-radius: 3px;
  margin-top: 1rem;
`;
