import { useCallback, useEffect, useState } from "react";

export const useGameStatus = ({ rowsCleared, stage }) => {
  const [score, setScore] = useState(0);
  const [rows, setRows] = useState(0);
  const [level, setLevel] = useState(0);

  // const linePoints = [40, 100, 300, 1200];

  const calcScore = useCallback(() => {
    // We have score
    // if (rowsCleared > 0) {
    //   // This is how original Tetris score is calculated
    //   setScore(prev => prev + linePoints[rowsCleared - 1] * (level + 1));
    //   setRows(prev => prev + rowsCleared);
    // }

    let _score = 0;

    stage.forEach((row) => {
      let colPoints = 0;
      row.forEach(([, b]) => {
        if (b !== "clear") {
          colPoints += 1;
        }
      });
      _score += colPoints;
    });

    if (score !== _score) {
      setScore(_score);
    }
  }, [score, stage]);

  useEffect(() => {
    calcScore();
  }, [calcScore, rowsCleared, score]);

  return [score, setScore, rows, setRows, level, setLevel];
};
